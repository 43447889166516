/**
 * Site header
 */
.site-header {
  border-top: 5px solid $border-color-03;
  border-bottom: 1px solid $border-color-01;
  min-height: $spacing-unit * 1.865;
  line-height: $base-line-height * $base-font-size * 2.25;

  // Positioning context for the mobile navigation icon
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px; /* Adjust the header height as needed */
  background-color: #ffffff; /* Replace with your desired background color */
  z-index: 999;
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  color: #2a7ae2;

  @include media-query($on-palm) {
    padding-right: 45px;
  }

  &,
  &:visited {
    color: $site-title-color;
  }
}

.site-nav {
  position: absolute;
  top: 9px;
  right: $spacing-unit * .5;
  background-color: $background-color;
  border: 1px solid $border-color-01;
  border-radius: 5px;
  text-align: right;

  .nav-trigger {
    display: none;
  }

  .menu-icon {
    float: right;
    width: 36px;
    height: 26px;
    line-height: 0;
    padding-top: 10px;
    text-align: center;

    > svg path {
      fill: $border-color-03;
    }
  }

  label[for="nav-trigger"] {
    display: block;
    float: right;
    width: 36px;
    height: 36px;
    z-index: 2;
    cursor: pointer;
  }

  input ~ .trigger {
    clear: both;
    display: none;
  }

  input:checked ~ .trigger {
    display: block;
    padding-bottom: 5px;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;
    display: block;
    padding: 5px 10px;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 0;
    }
    margin-left: 20px;
  }

  @media screen and (min-width: $on-medium) {
    position: static;
    float: right;
    border: none;
    background-color: inherit;

    label[for="nav-trigger"] {
      display: none;
    }

    .menu-icon {
      display: none;
    }

    input ~ .trigger {
      display: block;
    }

    .page-link {
      display: inline;
      padding: 0;

      &:not(:last-child) {
        margin-right: 20px;
      }
      margin-left: auto;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid $border-color-01;
  padding-top: $spacing-unit * .5;
  padding-bottom: $spacing-unit * .25;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px; /* Adjust the footer height as needed */
  background-color: #ffffff; /* Replace with your desired background color */
  z-index: 999;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit * .5;
}

.feed-subscribe .svg-icon {
  padding: 5px 5px 2px 0
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper,
.social-links {
  @include relative-font-size(0.9375);
  color: $brand-color;
  text-align:center;
  justify-content: center;
}

.footer-text {
  margin-bottom: $spacing-unit * .2;
}

.footer-col {
  margin-bottom: $spacing-unit * .5;
}

.footer-col-1,
.footer-col-2 {
  width: calc(50% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: calc(100% - (#{$spacing-unit} / 2));
}

@media screen and (min-width: $on-large) {
  .footer-col-1 {
    width: calc(35% - (#{$spacing-unit} / 2));
  }

  .footer-col-2 {
    width: calc(20% - (#{$spacing-unit} / 2));
  }

  .footer-col-3 {
    width: calc(45% - (#{$spacing-unit} / 2));
  }
}

@media screen and (min-width: $on-medium) {
  .footer-col-wrapper {
    display: flex
  }

  .footer-col {
    width: calc(100% - (#{$spacing-unit} / 2));
    padding: 0 ($spacing-unit * .5);

    &:first-child {
      padding-right: $spacing-unit * .5;
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      padding-left: $spacing-unit * .5;
    }
  }
}



/**
 * Page content
 */
.profile-picture-container {
  width: 200px; /* Adjust the width to the desired size */
  height: 200px; /* Adjust the height to the desired size */
  border-radius: 50%; /* Create a circular shape */
  overflow: hidden; /* Hide any excess content */
  margin: 0 auto; /* Center the container horizontally */
  position: relative; /* Add this line */
  top: 50%; /* Add this line */
  margin-bottom: 10px;
}

.profile-picture {
  width: 100%; /* Take up the full width of the container */
  height: auto; /* Maintain the aspect ratio of the image */
  display: block; /* Ensure proper layout and spacing */
}

.page-content {
  margin-top: $spacing-unit;
  padding-top: 50px;
  padding-bottom: 90px;
  margin-bottom: $spacing-unit;
  flex: 1 0 auto;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-container {
  max-width: 800px;
  margin: 0 auto;
}

.post-list-container h2 {
  text-align: center;
}

.post-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.post-list li {
  margin-bottom: 10px;
}

.post-meta {
  margin-bottom: 5px;
  font-size: $small-font-size;
  color: $brand-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}

/**
 * Posts
 */
.post-header {
  margin-bottom: 15px;
}

.post-title {
  margin-bottom: 5px;
}

.post-title,
.post-content h1 {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1.15;

  @media screen and (min-width: $on-large) {
    @include relative-font-size(2.625);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1, h2, h3, h4, h5, h6 { margin-top: $spacing-unit }

  h2 {
    @include relative-font-size(1.75);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(2);
    }
  }

  h3 {
    @include relative-font-size(1.375);

    @media screen and (min-width: $on-large) {
      @include relative-font-size(1.625);
    }
  }

  h4 {
    @include relative-font-size(1.25);
  }

  h5 {
    @include relative-font-size(1.125);
  }
  h6 {
    @include relative-font-size(1.0625);
  }
}

.post-navigation {
  display: grid;
  align-items: flex-start;
  margin-top: 1.5em;
}

.post-navigation.has-previous {
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
}

.post-navigation .previous-post,
.post-navigation .next-post {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s ease;
}

.post-navigation .previous-post:hover,
.post-navigation .next-post:hover {
  color: #0056b3;
}

.post-navigation .previous-post {
  text-align: left;
}

.post-navigation .next-post {
  text-align: right;
  margin-left: auto;
}

.social-media-list {
  display: table;
  margin: 0 auto;
  li {
    float: left;
    margin: 5px 10px 5px 0;
    &:last-of-type { margin-right: 0 }
    a {
      display: block;
      padding: 10px 12px;
      border: 1px solid $border-color-01;
      &:hover {
        filter: brightness(80%);
        border-color: $border-color-02;
      }
    }
  }
}


/**
 * Scroll bar always visible
 */

body {
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}



/**
 * Pagination navbar
 */
.pagination {
  margin-bottom: $spacing-unit;
  @extend .social-media-list;
  li {
    a, div {
      min-width: 41px;
      text-align: center;
      box-sizing: border-box;
    }
    div {
      display: block;
      padding: $spacing-unit * .25;
      border: 1px solid transparent;

      &.pager-edge {
        color: $border-color-01;
        border: 1px dashed;
      }
    }
  }
}



/**
 * Grid helpers
 */
@media screen and (min-width: $on-large) {
  .one-half {
    width: calc(50% - (#{$spacing-unit} / 2));
  }
}
